<template>
  <div>
    <div class="simple-page" style="display: flex">
      <div class="group" v-for="group in options" :key="group.id">
        <strong>{{group.content}}</strong>
        <Container
          :behaviour="group.clone ? 'copy' : 'clone'"
          group-name="1"
          :get-child-payload="(idx) => getChildPayload(idx, group.values)"
          @drop="onDropById(group.id, $event)"
        >
          <Draggable v-for="item in group.values" :key="item.id">
            <DraggableItem
              :addBox="group.id === 1"
              :item="item"
              v-model:inputText="item.data.inputText"
            />
          </Draggable>
        </Container>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref, onBeforeMount } from "vue";
import { AnswerHandler } from "@moods/taitaen-core";

import { Container, Draggable, api } from "@moods/vue3-smooth-dnd-wrapper"
import DraggableItem from "./combine/DraggableItem";

export default {
  name: "Combine",
  components: { Container, Draggable, DraggableItem },
  props: ["data", "isTest", "taskId", "taskType"],
  setup(props) {
    const { createAnswer, getAnswersInArray } = AnswerHandler();
    const answers = ref(createAnswer(props.taskId));
    const options = ref(null);
    const items = ref(null)
    const { applyDrag } = api();

    onBeforeMount(() => {
      setupVariables(props.data.options);
    });

    function setupVariables(data) {
      options.value = data;
      // options.value = [];
      items.value = [];

      let dataObj;
      for (let i in data) {
        // options.value.push({
        //   id: data[i].id,
        //   content: data[i].content,
        // });

        for (let j in data[i].values) {
          dataObj = {
            id: data[i].values[j].id,
            value: [],
          };
          //data[i].values[j]
          answers.value.push(dataObj);
          items.value.push(data[i].values[j]);
        }
      }
    }

    // function onDrop(collection, dropResult) {
    //   items.value[collection] = applyDrag(items.value[collection], dropResult);
    // }

    function onDropById(collectionId, dropResult) {
      const collection = options.value.find(({id}) => id === collectionId)
      // let collection;
      // let selectedCollectionId;
      // for (let i in options.value) {
      //   if (options.value[i].id === collectionId) {
      //     collection = options.value[i]
      //     selectedCollectionId = i;
      //     break;
      //   }
      // }
      
      if (check(dropResult) && isDuplicate(collection, dropResult.payload.id)) {
        return;
      }
      collection.values = applyDrag(collection.values, dropResult);

      const answer = answers.value.find(({id}) => id === dropResult.payload.id)
      const collections = [];
      let isInData;
      for (let i in options.value) {
        isInData = options.value[i].values.find(({id}) => id === dropResult.payload.id)
        if (isInData) {
          collections.push(options.value[i].id)
        }
      }
      answer.value = collections;
      getAnswersInArray();
      //items.value[collection] = applyDrag(items.value[collection], dropResult);
    }

    function check(dropResult) {
      if (dropResult.addedIndex !== null && dropResult.removedIndex === null) {
        return true;
      }
      return false;
    }

    function isDuplicate(collection, itemId) {
      if (props.data.settings && props.data.settings.disableDuplicate) {
        const result = collection.values.find(({id}) => id === itemId)
        if (result) {
          return true;
        }
      }
      return false;
    }

    function getChildPayload(index, collection) {
      return collection[index];
    }

    return {
      answers: computed(() => answers.value),
      options: computed(() => options.value),
      getChildPayload,
      onDropById,
    };
  },
};
</script>

<style lang="scss" scoped>
.group {
  width: 33.3333%;
}
.smooth-dnd-container {
  min-height: 300px;
  padding: 10px;
  border: 2px dashed gray;
  //transition: height 0.3s ease, min-height 0.3s ease, border 0.3s ease;
  transition: all 0.3s ease;
}
.smooth-dnd-stretcher-element.vertical {
  height: 0px;
  //transition: min-height 0.3s ease, height 0.3s ease;
  transition: all 0.3s ease;
}

.smooth-dnd-draggable-wrapper:not(.animated) {
  cursor: grab;
}
body.smooth-dnd-no-user-select.smooth-dnd-disable-touch-action * {
  cursor: grabbing;
}
</style>